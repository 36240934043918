import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Notification from "../notification/Notification";
import { Link } from "react-router-dom";
// import "./SignUpPage.css";
import "../../App.css";
import "../../themes.css";

function SignUpPage() {
	const [formData, setFormData] = useState({
		username: "",
		name: "",
		email: "",
		password: "",
	});
	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState("");
	const navigate = useNavigate();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const url = "https://www.getawai.online/register";

		try {
			await axios.post(url, formData, { withCredentials: true });
			setMessage("User registered successfully!");
			setMessageType("success");
			setTimeout(() => navigate("/login"), 2000); // Redirect to login page after 2 seconds
		} catch (error) {
			setMessage(
				error.response?.data?.error || "Registration failed. Please try again."
			);
			setMessageType("error");
		}
	};

	return (
		<div
			className="min-h-screen flex flex-col justify-center items-center"
			style={{
				backgroundColor: "var(--background-color)",
				color: "var(--text-color)",
			}}
		>
			<div
				className="w-full max-w-md px-6 py-8 shadow-md rounded-lg"
				style={{ backgroundColor: "var(--form-background-color)" }}
			>
				{message && <Notification message={message} type={messageType} />}
				<form onSubmit={handleSubmit} className="space-y-6">
					<h2 className="text-center text-3xl font-extrabold text-gray-900">
						Sign Up
					</h2>
					<div className="space-y-1">
						<label
							htmlFor="username"
							className="block text-sm font-medium text-gray-700"
						>
							Username
						</label>
						<input
							type="text"
							id="username"
							name="username"
							value={formData.username}
							onChange={handleInputChange}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="space-y-1">
						<label
							htmlFor="name"
							className="block text-sm font-medium text-gray-700"
						>
							Name
						</label>
						<input
							type="text"
							id="name"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="space-y-1">
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="space-y-1">
						<label
							htmlFor="password"
							className="block text-sm font-medium text-gray-700"
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							value={formData.password}
							onChange={handleInputChange}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<button
						type="submit"
						className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Sign Up
					</button>
					<p className="text-sm text-center">
						Already have an account?{" "}
						<Link
							to="/login"
							className="font-medium text-indigo-600 hover:text-indigo-500"
						>
							Log in
						</Link>
					</p>
				</form>
			</div>
		</div>
	);
}

export default SignUpPage;
