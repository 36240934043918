import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./DestinationDetails.css";
import MapComponent from "../MapComponent/MapComponent";

const DestinationDetails = () => {
  const { placeId } = useParams();
  const [placeDetails, setPlaceDetails] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPhotoUrls = async (photos) => {
      return Promise.all(photos.map(async (photo) => {
      const response = await axios.get(`https://www.getawai.online/get_photo_url`, {
        params: { photo_reference: photo.photo_reference },
      });
      return response.data.photo_url; //url for the photo
      }));
    };
    
    const fetchData = async () => {
      try {
      const response = await axios.get(`https://www.getawai.online/get_place_details?place_id=${placeId}`);
      const photoUrls = await fetchPhotoUrls(response.data.result.photos);
      setPlaceDetails({ ...response.data.result, photos: photoUrls });
      } catch (error) {
      console.error("Failed to fetch place details:", error);
      }
    };
    
    fetchData();
  }, [placeId]);

  const handlePlanTripClick = () => {
    navigate('/planning', { state: { destinationTitle: placeDetails.name } });
  };
  
  if (!placeDetails) return <div className="loading">Loading...</div>;

  return (
    
    <div className="destination-details-container">
     <Carousel 
        showArrows={false} 
        showStatus={false} 
        showThumbs={false} 
        autoPlay={true} 
        infiniteLoop={true} 
        useKeyboardArrows={true} 
        swipeable={true} 
        stopOnHover={false} 
        showIndicators={true}
      >
      {placeDetails.photos?.map((url, index) => (
        <div key={index} className="carousel-image">
          <img 
            src={url} 
            alt={`${placeDetails.name} - view ${index + 1}`} 
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </div>
      ))}
    </Carousel>


      <div className="destination-content">
        <h1 className="destination-title">{placeDetails.name}</h1>
        <p className="destination-description">{placeDetails.description || 'No description available.'}</p>
        <div className="destination-rating">
          <span>Rating: {placeDetails.rating} ({placeDetails.user_ratings_total} reviews)</span>
        </div>
        <div className="destination-info">
          <p><strong>Address:</strong> {placeDetails.formatted_address}</p>
          <p><strong>Phone:</strong> {placeDetails.formatted_phone_number}</p>
          <div className="destination-opening-hours">
            <strong>Opening Hours:</strong>
            <ul>
              {placeDetails.opening_hours?.weekday_text.map((day, index) => (
                <li key={index}>{day}</li>
              ))}
            </ul>
          </div>
          <div className="destination-reviews">
            <h2>Visitor Reviews</h2>
            {placeDetails.reviews?.map((review, index) => (
              <div key={index} className="review">
                <p><strong>{review.author_name}</strong> - {review.rating} stars</p>
                <p>{review.text}</p>
              </div>
            ))}
          </div>
        </div>
              <div>
      <h1>Travel Map</h1>
      <MapComponent />
    </div>
      </div>
      <button onClick={handlePlanTripClick} className="plan-trip-button">Plan Your Trip</button>

    </div>
    
  );
};

export default DestinationDetails;
