import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Notification from "../notification/Notification";
import "../../App.css"

function PasswordReset() {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState({ content: "", type: "" });
    const [codeSent, setCodeSent] = useState(false);
    const [sendingCode, setSendingCode] = useState(false);
    const navigate = useNavigate();

    const requestReset = async () => {
        if (sendingCode) return; // prevent sending if already processing

        setSendingCode(true);
        try {
            await axios.post("https://www.getawai.online/request-reset", { email });
            setMessage({ content: "Code sent. Please check your email.", type: "success" });
            setCodeSent(true);
        } catch (error) {
            setMessage({ content: "Failed to send code. Please try again.", type: "error" });
        }
    };

    const resetPassword = async () => {
        try {
            await axios.post("https://www.getawai.online/reset-password", { email, code, newPassword });
            setMessage({ content: "Password updated successfully.", type: "success" });
            navigate("/login");
        } catch (error) {
            setMessage({ content: "Failed to reset password. Please try again.", type: "error" });
        }
    };

    return (
        <div className="min-h-screen flex justify-center items-center bg-gray-100">
            <div className="max-w-md w-full space-y-8">
                <Notification message={message.content} type={message.type} />
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <input
                            className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                codeSent && "opacity-50"
                            }`}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            disabled={codeSent}
                        />
                    </div>
                    {!codeSent && (
                        <div className="flex items-center justify-between">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 w-full rounded focus:outline-none focus:shadow-outline"
                                onClick={requestReset}
                                disabled={sendingCode}
                            >
                                Send Code
                            </button>
                        </div>
                    )}
                    {codeSent && (
                        <>
                            <div className="mb-4">
                                <input
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Verification Code"
                                />
                            </div>
                            <div className="mb-6">
                                <input
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="New Password"
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 w-full rounded focus:outline-none focus:shadow-outline"
                                    onClick={resetPassword}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
    
}

export default PasswordReset;
