import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext";

function ProtectedRoute({ children, adminOnly = false }) {
	const { currentUser, isAdmin, loading } = useUser();

	if (loading) {
		return <div>Loading...</div>;
	}

	// If adminOnly is true, check if the user is an admin
	if (adminOnly && !isAdmin) {
		return <Navigate to="/home" />; // Redirect non-admins
	}

	return currentUser ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
