// HomePage.js
import React from "react";
import "./HomePage.css";
import TopDestinations from "../TopDestinations/TopDestinations";
import SearchSection from "../searchSection/SearchSection";

const HomePage = () => {
	const [searchQuery, setSearchQuery] = React.useState("");

	const handleSearch = (query) => {
		setSearchQuery(query);
	};

	return (
		<div className="home-container">
			<SearchSection onSearch={handleSearch} />
			<TopDestinations searchQuery={searchQuery} />
			<FeaturedItineraries />
			<NewsletterSignup />
			<AboutGetawai />
			<ContactInfo />
		</div>
	);
};

const FeaturedItineraries = () => {
	return (
		<section className="featured-itineraries">
			<h2>Featured Itineraries</h2>
			<div className="itineraries-list">
				<article className="itinerary">
					<h3>Romantic Paris Getaway</h3>
					<p>
						Explore the city of love with our curated 7-day itinerary that
						covers all the romantic sights and sounds of Paris.
					</p>
				</article>
				<article className="itinerary">
					<h3>Adventure in the Rockies</h3>
					<p>
						Embark on a thrilling 5-day adventure through the Rocky Mountains,
						filled with hiking, rafting, and camping.
					</p>
				</article>
				<article className="itinerary">
					<h3>Cultural Tour of Japan</h3>
					<p>
						Immerse yourself in the rich culture of Japan with a 10-day tour
						that spans from bustling Tokyo to tranquil Kyoto.
					</p>
				</article>
			</div>
		</section>
	);
};

const NewsletterSignup = () => {
	return (
		<section className="newsletter-signup">
			<h2>Stay Updated</h2>
			<form>
				<input type="email" placeholder="Enter your email" />
				<button type="submit" className="subscribe-button">
					Subscribe
				</button>
			</form>
		</section>
	);
};

const AboutGetawai = () => {
	return (
		<section className="about-getawai">
			<h2>About Getawai</h2>
			<p>
				Discover how Getawai can personalize your travel experience with unique,
				tailored itineraries that reflect your interests and preferences.
			</p>
		</section>
	);
};

const ContactInfo = () => {
	return (
		<section className="contact-info">
			<h2>Contact Us</h2>
			<p>
				Have questions? Get in touch with our team via email at
				contact@getawai.com or call us at +1234567890.
			</p>
		</section>
	);
};

export default HomePage;
