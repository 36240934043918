import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from "react";
import axios from "axios";

export const UserContext = createContext({
	currentUser: null,
	setCurrentUser: () => {},
	verifyToken: () => {},
	isAdmin: false,
	loading: true,
	handleLogout: () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);

	const verifyToken = useCallback(async () => {
		try {
			const response = await axios.get("https://www.getawai.online/verify_token", {
				withCredentials: true, //cookies are sent with the request
			});
			console.log("user");
			console.log(response.data);
			setCurrentUser(response.data); //response data is the user object
			setIsAdmin(response.data.is_admin);
			setLoading(false);
		} catch (error) {
			console.error("Verification failed", error);
			setCurrentUser(null);
			setIsAdmin(false);
			setLoading(false);
		}
	}, []);

	const handleLogout = async () => {
		try {
			await axios.get("https://www.getawai.online/logout", {
				withCredentials: true,
			});
			localStorage.removeItem("access-token");
			setCurrentUser(null);
			setIsAdmin(false);
			window.location.href = "/login";
		} catch (error) {
			console.error("Logout failed", error);
		}
	};

	useEffect(() => {
		verifyToken();
	}, [verifyToken]);

	const contextValue = {
		currentUser,
		setCurrentUser,
		verifyToken,
		loading,
		isAdmin,
		handleLogout,
	};

	return (
		<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
	);
};
