import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MapComponent = () => {
	const mapContainerRef = useRef(null);
	const mapboxAccessToken =
		"pk.eyJ1IjoiZ29kdmxhZGVyIiwiYSI6ImNsdjVpYXplcTAycnQyanBuZzJpZnJ4cTQifQ.RQ90rMNGIuiym6DK5GaG2w";

	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11", // style URL
			center: [4.3517, 50.8503], // starting position [lng, lat] for Brussels
			zoom: 9, // starting zoom
			accessToken: mapboxAccessToken,
		});

		return () => map.remove();
	}, []); // empty array to only run once

	return (
		<div>
			<div ref={mapContainerRef} style={{ width: "120%", height: "50vh" }} />
		</div>
	);
};

export default MapComponent;
