import React, { useEffect } from 'react';
import io from 'socket.io-client';

const SOCKET_URL = 'https://www.getawai.online/'; // Your server URL

const Socket = () => {
  useEffect(() => {
    const socket = io(SOCKET_URL, { withCredentials: true });

    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    socket.on('my response', (data) => {
      console.log(data);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });

    return () => {
      socket.off('connect');
      socket.off('my response');
      socket.off('disconnect');
      socket.close();
    };
  }, []);

  return <div>Socket.IO Test</div>;
};

export default Socket;
