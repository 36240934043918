import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import axios from "axios";
// import "./NavBar.css";
import "../../App.css";
import { useTheme } from "../../ThemeContext.tsx";
import { ThemeProvider } from "../../ThemeContext.tsx";

const NavBar = () => {
	const { handleLogout } = useUser();
	const navigate = useNavigate();
	const { currentUser, setCurrentUser } = useUser();
	const { toggleTheme } = useTheme();

	const navigateToProfile = () => {
		navigate("/profile");
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const profileResponse = await axios.get(
					"https://www.getawai.online/profile",
					{
						withCredentials: true,
					}
				);
				if (profileResponse.data) {
					setCurrentUser((prevState) => ({
						...prevState,
						...profileResponse.data,
					}));
				}
			} catch (error) {
				console.error("Failed to fetch user data:", error);
			}
		};

		// fetch only if photo is not present
		if (currentUser && !currentUser.photo_url) {
			fetchUserData();
		}
	}, [currentUser, setCurrentUser]);

	console.log(currentUser);
	return (
		<nav className="bg-gray-900 text-white p-4">
			<ThemeProvider>
				<div className="container mx-auto flex justify-between items-center">
					<div
						className="logo font-bold text-xl cursor-pointer"
						onClick={() => navigate("/")}
					>
						YourLogo
					</div>
					<div className="nav-links flex space-x-4">
						{currentUser && (
							<a href="/home" className="hover:text-gray-300">
								Home
							</a>
						)}
						<a href="#about" className="hover:text-gray-300">
							About
						</a>
						<a href="#services" className="hover:text-gray-300">
							Services
						</a>
					</div>
					<div className="flex items-center space-x-4">
						<button onClick={toggleTheme}>Toggle Theme</button>
						{currentUser && (
							<div
								className="user-info flex items-center space-x-2"
								onClick={navigateToProfile}
							>
								{currentUser.photo_url && (
									<img
										src={currentUser.photo_url}
										alt="Profile"
										className="h-8 w-8 rounded-full object-cover"
									/>
								)}
								<span>{currentUser.username}</span>
								<button
									onClick={handleLogout}
									className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
								>
									Logout
								</button>
							</div>
						)}
					</div>
				</div>
			</ThemeProvider>
		</nav>
	);
};

export default NavBar;
