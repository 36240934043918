import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import CreatableSelect from "react-select/creatable";
import { ThemeProvider } from "../../ThemeContext.tsx";
import "../../App.css";

//TODO
// Travel Preferences
// Accommodation Type: Preferences for hotels, hostels, apartments, or camping.
// Transportation Preferences: Preferred modes of travel, such as flying, trains, buses, or car rentals.
// Activity Interests: Types of activities the user enjoys, like hiking, museums, beach, nightlife, or cultural events.
// Hobbies: Collecting, sports, reading, music, gaming, etc. This could help in suggesting activities or destinations.
// Gamification: Encourage completion of profile details with badges, rewards, or progress bars.
// Festivals and Seasonal Events: Interest in festivals or events, like cherry blossoms in Japan or the Northern Lights in Scandinavia.

const foodOptions = [
	{ value: "vegan", label: "Vegan" },
	{ value: "vegetarian", label: "Vegetarian" },
	{ value: "glutenFree", label: "Gluten-Free" },
	{ value: "halal", label: "Halal" },
	{ value: "kosher", label: "Kosher" },
];

function UserProfile() {
	const navigate = useNavigate();
	const { setCurrentUser } = useUser();
	const [userData, setUserData] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [bio, setBio] = useState("");
	const [socialLinks, setSocialLinks] = useState({
		instagram: "",
		tiktok: "",
		facebook: "",
	});
	const [foodPreferences] = useState("");

	const [selectedFoodPreferences, setSelectedFoodPreferences] = useState([]);

	const handleFoodPreferenceChange = (selectedOptions) => {
		setSelectedFoodPreferences(selectedOptions);
		const preferencesToSend = selectedOptions.map((option) => option.value);
		updateFoodPreferences(preferencesToSend); //debounced call
	};

	//function when a new option is created by the user
	const onCreateOption = (inputValue) => {
		const newOption = { label: inputValue, value: inputValue };
		const updatedPreferences = [...selectedFoodPreferences, newOption];
		setSelectedFoodPreferences(updatedPreferences);

		const preferencesToSend = updatedPreferences.map((option) => option.value);
		updateFoodPreferences(preferencesToSend); //the same debounced function used in handleFoodPreferenceChange
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateFoodPreferences = useCallback(
		debounce(async (foodPreferences) => {
			try {
				await axios.post(
					"https://www.getawai.online/update_food_preferences",
					{
						foodPreferences,
					},
					{
						withCredentials: true,
					}
				);
				console.log("Food preferences updated successfully.");
			} catch (error) {
				console.error("Failed to update food preferences:", error);
			}
		}, 300),
		[]
	);

	useEffect(() => {
		const fetchProfileData = async () => {
			try {
				const response = await axios.get("https://www.getawai.online/profile", {
					withCredentials: true,
				});

				setUserData(response.data);
				//response.data contains bio socialLinks foodPreferences
				setBio(response.data.bio || "");
				console.log(response.data);
				setSocialLinks(
					response.data.socialLinks || {
						instagram: "",
						twitter: "",
						facebook: "",
					}
				);
				//transform and set food preferences for CreatableSelect
				const transformedFoodPreferences = response.data.foodPreferences.map(
					(pref) => ({
						label: pref,
						value: pref,
					})
				);
				setSelectedFoodPreferences(transformedFoodPreferences);
			} catch (error) {
				console.error("Error fetching profile data:", error);
				navigate("/login");
			}
		};
		fetchProfileData();
	}, [navigate]);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const updateProfile = async (event) => {
		event.preventDefault();
		setLoading(true);
		const formData = new FormData();
		if (selectedFile) {
			formData.append("photo", selectedFile);
		}
		// Include bio, social links, and food preferences in the form data
		formData.append("bio", bio);
		formData.append("socialLinks", JSON.stringify(socialLinks));
		// formData.append("foodPreferences", foodPreferences);
		const foodPreferencesToSend = selectedFoodPreferences.map(
			(option) => option.value
		);
		formData.append("foodPreferences", JSON.stringify(foodPreferencesToSend));

		try {
			const response = await axios.post(
				"https://www.getawai.online/update_profile",
				formData,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setMessage("Profile updated successfully!");
			const updatedUserData = {
				...userData,
				photo_url: response.data.photo_url,
				// Update bio, socialLinks, and foodPreferences based on response
				bio: bio,
				socialLinks: socialLinks,
				foodPreferences: foodPreferences,
			};
			setUserData(updatedUserData); // Update the local user data state
			setCurrentUser(updatedUserData); // Update user context if needed
		} catch (error) {
			setMessage("Failed to update profile. Please try again.");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <div>Loading...</div>;

	return (
		<ThemeProvider>
			<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
				User Profile
			</h2>
			{message && (
				<div className="text-white bg-green-500 p-3 rounded">{message}</div>
			)}
			{userData && (
				<>
					<div className="flex items-center space-x-4 my-4">
						{/* Check if there's a photo URL to display */}
						{userData.photo_url && (
							<label htmlFor="photo-upload" className="cursor-pointer">
								<img
									src={userData.photo_url}
									alt="User profile"
									className="w-24 h-24 rounded-full object-cover"
								/>
							</label>
						)}
						<div>
							<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
								Email: {userData.email}
							</p>
							<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
								Username: {userData.username}
							</p>
							<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
								Name: {userData.name}
							</p>
						</div>
					</div>
					<input
						type="file"
						id="photo-upload"
						onChange={handleFileChange}
						className="hidden"
					/>
					<div className="space-y-2">
						<label className="text-gray-700 dark:text-gray-300">Bio:</label>
						<textarea
							value={bio}
							onChange={(e) => setBio(e.target.value)}
							className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
						/>
					</div>
					<div className="space-y-2">
						<label className="text-gray-700">Instagram:</label>
						<input
							type="text"
							value={socialLinks.instagram}
							onChange={(e) =>
								setSocialLinks({
									...socialLinks,
									instagram: e.target.value,
								})
							}
							className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>
					<div className="space-y-2">
						<label className="text-gray-700">Tiktok:</label>
						<input
							type="text"
							value={socialLinks.tiktok}
							onChange={(e) =>
								setSocialLinks({
									...socialLinks,
									tiktok: e.target.value,
								})
							}
							className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>
					{/* Repeated for Instagram, Twitter, Facebook with similar styling */}
					<div className="space-y-2">
						<label className="text-gray-700 dark:text-gray-300">
							Food Preferences:
						</label>
						<CreatableSelect
							isMulti
							name="foodPreferences"
							options={foodOptions}
							className="basic-multi-select"
							classNamePrefix="select"
							placeholder={"ex: Vegan, Gluten-Free, allergic to water, etc"}
							onChange={handleFoodPreferenceChange}
							value={selectedFoodPreferences}
							onCreateOption={onCreateOption}
						/>
					</div>
					<form
						onSubmit={updateProfile}
						encType="multipart/form-data"
						className="space-y-4"
					>
						<div className="flex items-center space-x-2">
							<label className="text-gray-700 dark:text-gray-300">
								Profile Photo:
							</label>
							<input
								type="file"
								onChange={handleFileChange}
								className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 dark:text-gray-300 dark:bg-gray-700"
							/>
						</div>
						<button
							type="submit"
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						>
							Update Profile
						</button>
					</form>
				</>
			)}
		</ThemeProvider>
	);
}

export default UserProfile;
