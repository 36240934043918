import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import axios from 'axios';
import './TripFormPage.css';

const TripFormPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const destinationTitle = location.state?.destinationTitle;
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [travelType, setTravelType] = useState('solo');
    const [groupSize, setGroupSize] = useState(1);
    const [stayDuration, setStayDuration] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const [detourRange, setDetourRange] = useState('0');
    const [activityRangeKm, setActivityRangeKm] = useState('0');
    const [dailyBudget, setDailyBudget] = useState('');
    const [availableAccommodations, setAvailableAccommodations] = useState([]);
    const [selectedAccommodation, setSelectedAccommodation] = useState("");
    const [travelInterests] = useState([]);
    const [transportPreferences] = useState([]);
    const [totalBudget, setTotalBudget] = useState(0);
    const [availableInterests, setAvailableInterests] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [availableTransports, setAvailableTransports] = useState([]);
    const [selectedTransports, setSelectedTransports] = useState([]);


    useEffect(() => {
      if (startDate && endDate) {
        const duration = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
        setStayDuration(duration > 0 ? duration : 0);
      }
    }, [startDate, endDate]);

    useEffect(() => {
      const multiplier = travelType === 'couple' ? 2 : (travelType === 'group' ? groupSize : 1);
      setTotalBudget(dailyBudget * stayDuration * multiplier);
    }, [dailyBudget, stayDuration, travelType, groupSize]);

    const fetchItems = async () => {
      try {
        const accommodationsResp = await axios.get('https://www.getawai.online/api/getAccommodations', { withCredentials: true });
        const parsedAccommodations = accommodationsResp.data.map(item => JSON.parse(item));
        setAvailableAccommodations(parsedAccommodations);
    
        const transportsResp = await axios.get('https://www.getawai.online/api/getTransports', { withCredentials: true });
        const parsedTransports = transportsResp.data.map(item => JSON.parse(item));
        setAvailableTransports(parsedTransports);
    
        const interestsResp = await axios.get('https://www.getawai.online/api/getInterests', { withCredentials: true });
        const parsedInterests = interestsResp.data.map(item => JSON.parse(item));
        setAvailableInterests(parsedInterests);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(() => {
        fetchItems();
  
      }, []);


      const handleInterestCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSelectedInterests(prevState => ({
          ...prevState,
          [name]: checked
        }));
      };
      
      const handleTransportCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSelectedTransports(prevState => ({
          ...prevState,
          [name]: checked
        }));
      };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setIsFormDisabled(true);
      let apiCallFinished = false; // Flag to check if the API call is done
      let fetchedData = null; // To store fetched data

      toast.info("Generating your itinerary...", {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        onClose: () => {
          if (apiCallFinished) { // Only navigate if the API call is done
            navigate('/itinerary', { state: { itineraryData: fetchedData } });
          }
        },
      });

      console.log("Form submitted", { travelType, groupSize, startDate, endDate, detourRange, activityRangeKm, dailyBudget, accommodation: selectedAccommodation, travelInterests, transportPreferences, totalBudget });

      const formData = {
          destinationTitle,
          travelType,
          groupSize,
          stayDuration,
          startDate,
          endDate,
          activityRangeKm,
          detourRange,
          dailyBudget,
          accommodation: selectedAccommodation,
          travelInterests: selectedInterests, // Array of selected interests' names
          transportPreferences: selectedTransports,
          totalBudget
      };
      console.log("formData");
      console.log(formData);
      
      try {
        const response = await fetch('https://www.getawai.online/generate-itinerary', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });

        if (!response.ok) throw new Error('Network response was not ok.');

        fetchedData = await response.json(); // Store fetched data
    } catch (error) {
        console.error('Error:', error);
        toast.error("Failed to generate itinerary. Please try again.");
    } finally {
        // Always navigate after the API call is complete, regardless of the outcome.
        navigate('/itinerary', { state: { itineraryData: fetchedData } });
    }
          
    };
  

  return (
    <div className="trip-form-container">
      <h1>Plan Your Trip to {destinationTitle}</h1>
      <form onSubmit={handleSubmit}>
      <fieldset disabled={isFormDisabled}>
        <div className="form-group">
          <label>Travel Type:</label>
          <select value={travelType} onChange={(e) => setTravelType(e.target.value)}>
            <option value="solo">Solo</option>
            <option value="couple">Couple</option>
            <option value="group">Group</option>
          </select>
          {travelType === 'group' && (
            <input 
              type="number" 
              placeholder="Group Size" 
              value={groupSize} 
              onChange={(e) => setGroupSize(Number(e.target.value))}
              min="1"
            />
          )}
        </div>
        <div className="form-group">
          <label>Date Range:</label>
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            minDate={new Date()} //disable past dates
            dateFormat="dd/MM/yyyy" //date format to day/month/year
          />
          {/* <p>Start Date: {startDate ? format(startDate, 'dd/MM/yyyy') : 'Not selected'}</p>
          <p>End Date: {endDate ? format(endDate, 'dd/MM/yyyy') : 'Not selected'}</p> */}
        </div>
        <div className="form-group">
          <label>Comfortable detour range (in km)</label>
          <input type="number" value={detourRange} onChange={(e) => setDetourRange(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Find me attractions and activities within (km) of the destination</label>
          <input type="number" value={activityRangeKm} onChange={(e) => setActivityRangeKm(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Daily Budget:</label>
          <input type="number" value={dailyBudget} onChange={(e) => setDailyBudget(e.target.value)} />
        </div>
        <div className="form-group">
            <label>Accommodation Preferences:</label>
            <select value={selectedAccommodation} onChange={(e) => setSelectedAccommodation(e.target.value)}>
                <option value="" disabled selected>Select your accommodation</option>
                {availableAccommodations.map((acc) => (
                    <option key={acc._id.$oid} value={acc.name}>{acc.name}</option>
                ))}
            </select>
        </div>
        <div className="form-group">
            <label>Travel Interests (select all that apply):</label>
            <div className="checkbox-group">
            {availableInterests.map((interest) => (
            <label key={interest._id.$oid}>
              <span>{interest.name}</span>
              <input
                type="checkbox"
                name={interest.name}
                checked={!!selectedInterests[interest.name]}
                onChange={handleInterestCheckboxChange}
              />
            </label>
          ))}
          </div>
        </div>
        
        <div className="form-group">
            <label>Transport Preferences (select all that apply):</label>
            <div className="checkbox-group">
            {availableTransports.map((transport) => (
            <label key={transport._id.$oid}>
              <span>{transport.name}</span>
              <input
                type="checkbox"
                name={transport.name}
                checked={!!selectedTransports[transport.name]}
                onChange={handleTransportCheckboxChange}
              />
            </label>
          ))}
          </div>
        </div>
        <div className="form-group">
          <p>Total Budget: ${totalBudget.toFixed(2)}</p>
        </div>
        <button type="submit" className="submit-btn">Plan my trip</button>
      </fieldset>
      </form>
    </div>
  );
};

export default TripFormPage;
