// AdminPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../AdminPage/AdminPage.css";

const AdminPage = () => {
  // State for the new item inputs
  const [newAccommodation, setNewAccommodation] = useState('');
  const [newTransport, setNewTransport] = useState('');
  const [newInterest, setNewInterest] = useState('');

  // State for lists
  const [accommodations, setAccommodations] = useState([]);
  const [transports, setTransports] = useState([]);
  const [interests, setInterests] = useState([]);

  const [addItemError, setAddItemError] = useState('');

  const fetchItems = async () => {
    try {
      const accommodationsResp = await axios.get('https://www.getawai.online/api/getAccommodations', { withCredentials: true });
      const parsedAccommodations = accommodationsResp.data.map(item => JSON.parse(item));
      setAccommodations(parsedAccommodations);
  
      const transportsResp = await axios.get('hhttps://www.getawai.online/api/getTransports', { withCredentials: true });
      const parsedTransports = transportsResp.data.map(item => JSON.parse(item));
      setTransports(parsedTransports);
  
      const interestsResp = await axios.get('https://www.getawai.online/api/getInterests', { withCredentials: true });
      const parsedInterests = interestsResp.data.map(item => JSON.parse(item));
      setInterests(parsedInterests);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
      fetchItems();

    }, []);

    //GENERIC ADD FOR ANY ITEM

    const handleAddItemOptimistically = async (type, name) => {
      // Prevent adding if name is empty
      if (!name.trim()) {
        setAddItemError('Name cannot be empty.');
        return;
      }
  
      // Prepare the state update based on the type
      const newItem = { name };
      let isDuplicate = false;
      if (type === 'accommodations' && !accommodations.some(item => item.name === name)) {
        setAccommodations([...accommodations, newItem]);
      } else if (type === 'transports' && !transports.some(item => item.name === name)) {
        setTransports([...transports, newItem]);
      } else if (type === 'interests' && !interests.some(item => item.name === name)) {
        setInterests([...interests, newItem]);
      } else {
        isDuplicate = true;
        setAddItemError(`Error: ${name} already exists in ${type}.`);
      }
  
      // If item is a duplicate, don't proceed with the API call
      if (isDuplicate) return;
  
      try {
        // Attempt to add the item on the server
        await axios.post(`https://www.getawai.online/admin/${type}`, { name }, { withCredentials: true });
        console.log(`${type} added successfully:`, name);
        setAddItemError(''); // Clear any error message
        // Optionally, refresh items list here
      } catch (error) {
        console.error(`Failed to add ${type}:`, error);
        setAddItemError(error.response.data.error || `Failed to add ${type}. Please try again.`);
        // Revert UI changes in case of error
        if (type === 'accommodations') setAccommodations(accommodations.filter(item => item.name !== name));
        else if (type === 'transports') setTransports(transports.filter(item => item.name !== name));
        else if (type === 'interests') setInterests(interests.filter(item => item.name !== name));
      }
    };

    //SPECIFIC FUNCTIONS TO ADD EACH ITEM

    // const handleAddAccommodation = async (e) => {
    //   e.preventDefault();
    //   try {
    //     const url = 'http://localhost:5000/admin/accommodations'; 
    //     const formData = { name: newAccommodation };
    //     await axios.post(url, formData, { withCredentials: true });
    //     alert('Accommodation added successfully!');
    //     fetchItems();
    //     setNewAccommodation('');
    //   } catch (error) {
    //     console.error('Failed to add accommodation:', error);
    //     // Display a user-friendly error message
    //   }
    // };

    // const handleAddTransport = async (e) => {
    //   e.preventDefault();
    //   try {
    //       const url = 'http://localhost:5000/admin/transports'; 
    //       const formData = { name: newTransport };
    //       await axios.post(url, formData, { withCredentials: true });
    //       alert('Transport added successfully!');
    //       setNewTransport('');
    //     } catch (error) {
    //       console.error('Failed to add transport:', error);
    //       // Display a user-friendly error message
    //     }
    // };
  
    // const handleAddInterest = async (e) => {
    //   e.preventDefault();
    //   try {
    //       const url = 'http://localhost:5000/admin/interests'; 
    //       const formData = { name: newInterest };
    //       await axios.post(url, formData, { withCredentials: true });
    //       alert('Interest added successfully!');
    //       setNewInterest('');
    //     } catch (error) {
    //       console.error('Failed to add interest:', error);
    //       // Display a user-friendly error message
    //     }
    // };

    //SPECIFIC FUNCTIONS FOR DELETING EACH ITEM

    // const handleDeleteAccommodation = async (name) => {
    //   try {
    //     const response = await axios.delete(`http://localhost:5000/admin/accommodations/${name}`, {
    //       withCredentials: true
    //     });
    //     console.log(response.data.message);
    //     fetchItems();
    //   } catch (error) {
    //     console.error('Failed to delete accommodation:', error.response.data);
    //   }
    // };

    // const handleDeleteAccommodationOptimistically = async (type, name) => {
    //   const previousAccommodations = [...accommodations]; // Store the current state
    //   // Optimistically remove the accommodation from the UI
    //   const updatedAccommodations = accommodations.filter(item => item.name !== name);
    //   setAccommodations(updatedAccommodations);
    
    //   try {
    //     // Attempt to delete the accommodation on the server
    //     await axios.delete(`http://localhost:5000/admin/delete/accommodations/${name}`, { withCredentials: true });
    //     console.log(`Accommodation ${name} deleted successfully.`);
    //   } catch (error) {
    //     console.error(`Failed to delete accommodation:`, error.response.data);
    //     // Revert the change in case of an error
    //     setAccommodations(previousAccommodations);
    //   }
    // };

    const handleDeleteItemOptimistically = async (type, name) => {
      //current state
      const previousItems = {
          accommodations: [...accommodations],
          transports: [...transports],
          interests: [...interests]
      };
  
      //optimistically update the UI => removing the item before the actual deletion
      if (type === "accommodations") {
          setAccommodations(prev => prev.filter(item => item.name !== name));
      } else if (type === "transports") {
          setTransports(prev => prev.filter(item => item.name !== name));
      } else if (type === "interests") {
          setInterests(prev => prev.filter(item => item.name !== name));
      }
  
      try {
          //delete the item on the server
          await axios.delete(`https://www.getawai.online/admin/delete/${type}/${name}`, { withCredentials: true });
          console.log(`${type} ${name} deleted successfully.`);
      } catch (error) {
          console.error(`Failed to delete ${type}:`, error.response.data);
          // revert the UI update in case of an error
          if (type === "accommodations") {
              setAccommodations(previousItems.accommodations);
          } else if (type === "transports") {
              setTransports(previousItems.transports);
          } else if (type === "interests") {
              setInterests(previousItems.interests);
          }
      }
  };
  
    

  return (
    <div className="admin-page-container p-8">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>

      <div className="admin-section mb-8">
        {/* Show error message if it exists */}
      {addItemError && <div className="error-message">{addItemError}</div>}
        <h2 className="text-xl font-semibold">Manage Accommodations</h2>
        <form onSubmit={(e) => {
              e.preventDefault();
              handleAddItemOptimistically("accommodations", newAccommodation, setAccommodations, setNewAccommodation);
          }}>
          <input
            type="text"
            className="input-base"
            value={newAccommodation}
            onChange={(e) => setNewAccommodation(e.target.value)}
            placeholder="New Accommodation"
          />
          <button type="submit" className="btn-base ml-2">Add</button>
        </form>
        {/* // Example for accommodations. Repeat similar structure for transports and interests */}
        <ul className="list-disc pl-5 mt-2">
          {accommodations.map((item, index) => (
            <li key={index} className="flex justify-between items-center">
              {item.name}
              <button
                onClick={() => handleDeleteItemOptimistically('accommodations', item.name)}
                className="btn-delete ml-4 py-1 px-4"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="admin-section mb-8">
        <h2 className="text-xl font-semibold">Manage Transports</h2>
        <form onSubmit={(e) => {
              e.preventDefault();
              handleAddItemOptimistically("transports", newTransport, setTransports, setNewTransport);
          }}>
          <input
            type="text"
            className="input-base"
            placeholder="New transport"
            value={newTransport}
            onChange={(e) => setNewTransport(e.target.value)}
          />
          <button type="submit" className="btn-base ml-2">Add</button>
        </form>
        <ul className="list-disc pl-5 mt-2">
          {transports.map((item, index) => (
            <li key={index} className="flex justify-between items-center">
              {item.name}
              <button
                onClick={() => handleDeleteItemOptimistically('transports', item.name)}
                className="btn-delete ml-4 py-1 px-4"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="admin-section mb-8">
        <h2 className="text-xl font-semibold">Manage Interests</h2>
        <form onSubmit={(e) => {
              e.preventDefault();
              handleAddItemOptimistically("interests", newInterest, setInterests, setNewInterest);
          }}>
          <input
            type="text"
            className="input-base"
            placeholder="New interest"
            value={newInterest}
            onChange={(e) => setNewInterest(e.target.value)}
          />
          <button type="submit" className="btn-base ml-2">Add</button>
        </form>
        <ul className="list-disc pl-5 mt-2">
          {interests.map((item, index) => (
            <li key={index} className="flex justify-between items-center">
              {item.name}
              <button
                onClick={() => handleDeleteItemOptimistically('interests', item.name)}
                className="btn-delete ml-4 py-1 px-4"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPage;
