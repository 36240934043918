// Routes.js
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import WelcomePage from "./components/welcomepage/WelcomePage";
import SignUpPage from "./components/signup/SignUpPage";
import LoginPage from "./components/login/LogInPage";
import HomePage from "./components/homepage/HomePage";
import DestinationDetails from "./components/DestinationDetails/DestinationDetails";
import TripFormPage from "./components/TripForm/TripFormPage";
import ProtectedRoute from "./ProtectedRoute";
import ItineraryPage from "./components/ItineraryPage/ItineraryPage";
import UserProfile from "./components/UserProfile/UserProfile";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import AdminPage from "./components/AdminPage/AdminPage";
import Socket from "./components/SocketTest";

function AppRoutes() {
	const location = useLocation();
	const showNavBar = !["/"].includes(location.pathname);

	return (
		<>
			{showNavBar && <NavBar />}
			<Routes>
				<Route path="/" element={<WelcomePage />} />
				<Route path="/signup" element={<SignUpPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/forgot-password" element={<PasswordReset />} />
				<Route
					path="/adminpage"
					element={
						<ProtectedRoute adminOnly={true}>
							<AdminPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<UserProfile />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/socket"
					element={
						<ProtectedRoute>
							<Socket />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/destination/:placeId"
					element={
						<ProtectedRoute>
							<DestinationDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/planning"
					element={
						<ProtectedRoute>
							<TripFormPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/itinerary"
					element={
						<ProtectedRoute>
							<ItineraryPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/home"
					element={
						<ProtectedRoute>
							<HomePage />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</>
	);
}

export default AppRoutes;
