import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./TopDestinations.css";

const TopDestinations = ({ searchQuery }) => {
	const [destinations, setDestinations] = useState([]);
	//const scrollRef = useRef(null);
	const scrollContainerRef = useRef(null);

	const navigate = useNavigate();

	const handleDestinationClick = (destination) => {
		navigate(`/destination/${destination.place_id}`);
	};

	useEffect(() => {
	const handleWheel = (e) => {
		const scrollAmount = e.deltaY;
		if (scrollContainerRef.current) {
		scrollContainerRef.current.scrollLeft += 3*scrollAmount; // sensitivity 
		}
	};

	const container = scrollContainerRef.current;
	container.addEventListener('wheel', handleWheel, { passive: false });

	return () => {
		container.removeEventListener('wheel', handleWheel);
	};
	}, []);
	  

	useEffect(() => {
		const userQuery = encodeURIComponent(
			searchQuery || "attractions in Belgium"
		);
		fetch(`https://www.getawai.online/get_top_destinations?query=${userQuery}`, {
			credentials: "include",
		})
			.then((response) => response.json())
			.then((data) => setDestinations(data))
			.catch((error) =>
				console.error("Failed to fetch top destinations:", error)
			);
	}, [searchQuery]);

	return (
		<div className="top-destinations">
			<h2>Top Destinations</h2>
			<div
				className="destinations-scroll-container"
				ref={scrollContainerRef}
			>
				{destinations.map((destination, index) => (
					<div
						key={index}
						className="destination-card"
						onClick={() => handleDestinationClick(destination)}
					>
						<img
							src={destination.photo_url}
							alt={destination.name}
							className="destination-image"
						/>
						<div className="destination-name">{destination.name}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TopDestinations;
