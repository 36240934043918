import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Notification from "../notification/Notification";
import { useUser } from "../../UserContext";
import { Link } from "react-router-dom";
// import "./LoginPage.css";
import "../../App.css";
// import { ThemeProvider } from "../../ThemeContext.tsx";
// import { useTheme } from "../../ThemeContext.tsx";

function LoginPage() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState(""); //holding message text
	const [messageType, setMessageType] = useState(""); //holding message type ('success' or 'error')
	const navigate = useNavigate();
	const { setCurrentUser } = useUser();
	// const { theme } = useTheme();

	const handleSubmit = async (event) => {
		event.preventDefault();
		const url = "https://www.getawai.online/login";

		try {
			const response = await axios.post(
				url,
				{ email, password },
				{ withCredentials: true }
			);
			setCurrentUser(response.data.user);
			setMessage("Login successful!");
			setMessageType("success");

			setTimeout(() => {
				navigate("/home");
			}, 2000); // Delay (2 seconds)
		} catch (error) {
			setMessage(error.response?.data?.message || "Login failed");
			setMessageType("error");
		}
	};

	return (
		<div
			className="min-h-screen flex flex-col justify-center items-center"
			style={{
				backgroundColor: "var(--background-color)",
				color: "var(--text-color)",
			}}
		>
			<div
				className="w-full max-w-md px-6 py-8 shadow-md rounded-lg"
				style={{ backgroundColor: "var(--form-background-color)" }}
			>
				{message && <Notification message={message} type={messageType} />}
				<form onSubmit={handleSubmit} className="space-y-6">
					<h2 className="text-center text-3xl font-extrabold text-gray-900">
						Login
					</h2>
					<div className="space-y-1">
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div className="space-y-1">
						<label
							htmlFor="password"
							className="block text-sm font-medium text-gray-700"
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
					<div>
						<button
							type="submit"
							className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Log In
						</button>
					</div>
					<p className="text-sm text-center">
						Don't have an account?{" "}
						<Link
							to="/signup"
							className="font-medium text-indigo-600 hover:text-indigo-500"
						>
							Sign up
						</Link>
					</p>
					<p className="text-sm text-center">
						Forgot your password?{" "}
						<Link
							to="/forgot-password"
							className="font-medium text-indigo-600 hover:text-indigo-500"
						>
							Recover it
						</Link>
					</p>
				</form>
			</div>
		</div>
	);
}

export default LoginPage;
