import React from "react";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import logo from "../../images/logo.gif";

function WelcomePage() {
	const navigate = useNavigate();

	const handleSignUpClick = () => {
		navigate("/signup");
	};

	const handleLogInClick = () => {
		navigate("/login");
	};

	return (
		<div className="welcome-container">
			<div className="welcome-header">
				<h1 className="welcome-title">Welcome to Getawai</h1>
				<p className="welcome-subtitle">Experience the Future of Travel</p>
			</div>
			<div className="welcome-content">{/* Feature sections */}</div>
			<div className="welcome-3d-background">
				{/* logo */}
				<img src={logo} alt="Getawai Logo" className="spinning-logo" />
			</div>
			<div className="welcome-buttons">
				<button className="welcome-button" onClick={handleSignUpClick}>
					Sign Up
				</button>
				<button className="welcome-button" onClick={handleLogInClick}>
					Log In
				</button>
			</div>
		</div>
	);
}

export default WelcomePage;
