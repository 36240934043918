import React from "react";

const SearchSection = ({ onSearch }) => {
	const [searchQuery, setSearchQuery] = React.useState("");
	const [setSuggestions] = React.useState([]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onSearch(searchQuery); //pass search query the parent component
	};

	const handleInputChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
	};

	const clearSearch = () => {
		setSearchQuery("");
		setSuggestions([]);
	};

	return (
		<form className="search-section" onSubmit={handleSubmit}>
			<input
				type="text"
				placeholder="Where to next?"
				value={searchQuery}
				onChange={handleInputChange}
			/>
			{searchQuery && (
				<button onClick={clearSearch} type="button" className="clear-search">
					x
				</button>
			)}
			<button type="submit">Let's Go</button>
			{/*render suggestions*/}
		</form>
	);
};

export default SearchSection;
