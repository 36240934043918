import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const ItineraryPage = () => {
	const location = useLocation();
	const itineraryData = location.state?.itineraryData?.itinerary;
	const [activeDay, setActiveDay] = useState(null);

	const toggleDay = (dayId) => {
		setActiveDay(activeDay === dayId ? null : dayId);
	};

	return (
		<div className="container mx-auto p-4">
			<h1 className="text-2xl font-bold text-center mb-6">Your Itinerary</h1>
			{itineraryData ? (
				Object.entries(itineraryData).map(([day, activities], index) => (
					<div key={index} className="mb-4">
						<button
							className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg"
							onClick={() => toggleDay(index)}
						>
							{day}
						</button>
						<div
							className={`${
								activeDay === index ? "block" : "hidden"
							} bg-gray-100 p-4 mt-2 rounded-lg`}
						>
							{activities.map((activity, idx) => (
								<div key={idx} className="p-2 border-b last:border-b-0">
									<p>{activity.description}</p>
									{activity.modifiable && (
										<button
											className="mt-2 px-3 py-1 bg-green-500 text-white rounded"
											onClick={() => alert(`Modify Activity: ${activity.id}`)}
										>
											Modify Activity
										</button>
									)}
								</div>
							))}
						</div>
					</div>
				))
			) : (
				<p className="text-center">
					No itinerary data available. Please submit the form to generate an
					itinerary.
				</p>
			)}
		</div>
	);
};

export default ItineraryPage;
