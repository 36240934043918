import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
import { UserProvider } from "./UserContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./themes.css";
// import { useTheme } from "./ThemeContext.tsx";

function App() {
	// const { theme } = useTheme(); // This line will use the theme from the context

	return (
		<div>
			<ToastContainer />
			<UserProvider>
				<Router>
					<AppRoutes />
				</Router>
			</UserProvider>
		</div>
	);
}

export default App;
